<template>
  <v-dialog
      v-model="dialogShow"
      :max-width="myConfirmOptions.width"
      :style="{ zIndex: myConfirmOptions.zIndex }"
      @keydown.esc="dialogShow=false"
  >
    <v-card>
      <v-toolbar dark :color="myConfirmOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ myConfirmOptions.title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-title class="text-h6">{{myConfirmOptions.message1}}</v-card-title>
      <v-card-subtitle class="text--darken-1 subtitle-2">{{myConfirmOptions.message2}}</v-card-subtitle>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" outlined text @click="cancelConfirmed" >Cancel</v-btn>
        <v-btn color="blue darken-1" outlined text @click="okeConfirmed" ><v-icon small>mdi-check</v-icon> OK</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      selectedItems:[],
      selectedIndex: -1,
      dialogShow: false,
      myConfirmOptions: {
        title: 'Konfirmasi',
        message1: 'Yakin?',
        message2: '',
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
      },
    }
  },

  props: [
  ],
  computed: {
  },
  methods: {
    okeConfirmed: function(){
      this.$emit('eventFromOkeConfirmDialog', "oke")
    },
    cancelConfirmed: function(){
      this.$emit('eventFromCancelConfirmDialog', "cancel")
      this.dialogShow = false
    },
    showDialog(message1, message2) {
      this.myConfirmOptions.message1 = message1
      this.myConfirmOptions.message2 = message2
      this.dialogShow = !this.dialogShow
    },

    setDialogState(value){
      this.dialogShow =value
    }


  }

}
</script>

<style scoped>

</style>