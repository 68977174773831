import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class LangTranslatorService {
    getApiUrl() {
        return API_URL
    }

    getPostAzureTranslateResult(from, to, data){

        const params = {
            from: `${from}`,
            to: `${to}`,
        };

        let headers = authHeader();
        headers['Content-Type'] =  'text/plain'

        return axios.post(API_URL + `getAzureTranslateResult`, data, { headers, params });
    }
    getPostChatGptTranslateIdToEn(contentIndonesia){
        return axios.post(API_URL + `getChatGptTranslateIdToEn`, contentIndonesia, { headers: authHeader() });
    }
    getPostChatGptArrayTranslateIdToEn(arrayContentIndonesia){
        console.log(arrayContentIndonesia)
        return axios.post(API_URL + `getChatGptArrayTranslateIdToEn`, arrayContentIndonesia, { headers: authHeader() });
    }

}
export default new LangTranslatorService()